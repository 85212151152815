/*! Agile Pixel https://agilepixel.io - 2021*/
import './autoload/formStyle';
import './autoload/smoothScroll';

const requireImages = require.context('../images', true, /\.(png|jpe?g|gif|svg)$/);
requireImages.keys().forEach(requireImages);


// import local dependencies
import Router from './util/Router';
import moduleLoader from './util/moduleLoader';

/** Set Routes */
Router.setRoutes({common: 'common'});

/**
 * Load events
 * @return {undefined}.
 */
Router.loadEvents();
moduleLoader.scan(document);
