/*! Agile Pixel https://agilepixel.io - 2021*/
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const getOffset = function (element) {
    let _x = 0;
    let _y = 0;
    while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
        _x += element.offsetLeft - element.scrollLeft;
        _y += element.offsetTop - element.scrollTop;
        element = element.offsetParent;
    }
    return {
        top: _y,
        left: _x,
    };
};

const scrollCheck = function (event) {
    const $offset = 30;
    const link = event.currentTarget;
    let hash = link.getAttribute('href');
    hash = hash.replace(/^\//, '');
    const element = document.querySelector(hash);

    if (element) {
        const top = getOffset(element).top - $offset;

        window.scrollTo({
            top,
            behavior: 'smooth',
        });

        history.pushState({}, false, hash);
    } else {
        location.reload();
    }
};
window.addEventListener('hashchange', scrollCheck);

window.addEventListener('popstate', scrollCheck);

const $smoothScroll = document.querySelectorAll('.js-smoothScroll');
for (const element of $smoothScroll) {
    element.addEventListener('click', event => {
        event.preventDefault();

        scrollCheck(event);
    });
}
